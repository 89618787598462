import { Outlet } from 'react-router-dom';

import Footer from 'components/src/atoms/Footer';
import Header from 'components/src/atoms/Header';

const LayoutComponent = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default LayoutComponent;
