/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { buildSop } from 'components/src/helpers/sop';
import React from 'react';

// const SOP_BASE = {
//   der_: { label: 'Loja' },
//   mki_: { label: 'Marca' },
//   mdi_: { label: 'Modelo' },
//   iyr_: { label: 'Ano mínimo' },
//   fyr_: { label: 'Ano maximo' },
//   kmm_: { label: 'Km' },
//   ipr_: { label: 'Preço mínimo' },
//   fpr_: { label: 'Preço maximo' },
//   trm_: { label: 'Câmbio' },
//   clr_: { label: 'Cor' },
//   fue_: { label: 'Combustivel' },
//   // plt_: { label: 'Placa' },
//   // vtp_: 'CAR',
// };

const sopRaw = {
  plt_: null,
  trm_: null,
  fue_: null,
  clr: null,
  der_: null,
  lpt_: null,
  mki_: null,
  mdi_: null,
  iyr_: null,
  bds_: null,
  doo_: null,
  ste_: null,
  bkd_: null,
  blk_: null,
  wod_: null,
  avl_: null,
  ops_: null,
  ipy_: null,
  fpi_: null,
  vtp_: 'CAR',
  egt_: null,
  rcb_: null,
  geo_: null,
};

interface SopProps {
  sop: any;
  currentFilters: any;
  currentPage: number;
  deals: any[];
  handleChangeSop: () => void;
  handleCleanSop: () => void;
  setCurrentPage: (page: number) => void;
  setDeals: (value: any[]) => void;
}

export type StepperData = 'ModelsStep' | 'MakesStep' | 'ModelYearsStep';

const SopContext = React.createContext({});

const SopContextProvider = ({ children }) => {
  const [sop, setSop] = React.useState(sopRaw);
  const [currentFilters, setCurrentFilters] = React.useState<any>({});
  const [currentPage, setCurrentPage] = React.useState(1);
  const [deals, setDeals] = React.useState([]);
  const [steps, setSteps] = React.useState<StepperData>('MakesStep');

  const handleGetCurrents = (sopKey, sopValue, sopName, changeCurrent) => {
    if (
      !changeCurrent &&
      Object.values(currentFilters).find(
        (filter: { sopName: string; sopValue: string | number }) =>
          filter?.sopName === sopName,
      )
    ) {
      delete currentFilters[`${sopKey}-${sopName}`];
      setCurrentFilters(currentFilters);
      return;
    }
    setCurrentFilters((prevState) => ({
      ...prevState,
      [changeCurrent ? sopKey : `${sopKey}-${sopName}`]: {
        sopValue,
        sopKey,
        sopName,
        newKey: changeCurrent ? sopKey : `${sopKey}-${sopName}`,
      },
    }));
  };

  const handleRemoveFilter = (sopKey: string) => {
    const currentFiltersAux = currentFilters;
    let sopAux = sop;
    const findedFilter: any = Object.values(currentFiltersAux).find(
      (filterObject: any) => filterObject?.newKey === sopKey,
    );
    setDeals([]);
    setCurrentFilters(1);

    sopAux = buildSop(
      sopAux,
      findedFilter.sopKey,
      sopKey === 'mki_' ? null : findedFilter.sopValue,
      false,
    );

    if (sopKey === 'mki_' && sopAux['mdi_']) {
      sopAux = buildSop(sopAux, 'mdi_', null, false);
      Object.values(currentFiltersAux).forEach((filterObject: any) => {
        if (filterObject.sopKey === 'mdi_') {
          delete currentFiltersAux[filterObject.newKey];
        }
      });
    }

    if (findedFilter) {
      delete currentFiltersAux[findedFilter.newKey];
    }

    setCurrentFilters(currentFiltersAux);
    setSop(sopAux);
  };

  type filterObjectType = {
    id: string | number;
    name: string;
  };

  const handleChangeSop = (
    sopKey: string | string[],
    sopValue: string | number | string[] | number[],
    changeCurrent = false,
    filterObject: filterObjectType,
    filtersObject: filterObjectType[] = undefined,
    clearModels: false,
  ) => {
    let sopBuilded = sop;
    setDeals([]);
    setCurrentPage(1);

    if (typeof sopKey === 'string' && filterObject.name) {
      sopBuilded = buildSop(sop, sopKey, sopValue, changeCurrent);
      handleGetCurrents(sopKey, sopValue, filterObject.name, changeCurrent);
      if (clearModels && !!sopBuilded['mdi_']) {
        sopBuilded = cleanSopFilterByKeys(['mdi_'], sopBuilded);
      }
    } else {
      for (let i = 0; i < sopKey?.length; i++) {
        sopBuilded = buildSop(
          sopBuilded,
          sopKey[i],
          sopValue[i],
          changeCurrent,
        );
        handleGetCurrents(
          sopKey[i],
          sopValue[i],
          filtersObject[i].name,
          changeCurrent,
        );
      }
    }

    setSop(sopBuilded);
  };

  const cleanSopFilterByKeys = (sopKeys: string[] = [], currentSop) => {
    try {
      let sopBuilded = currentSop;

      sopKeys.forEach((sopKey) => {
        const findedFilter: any = Object.values(currentFilters).find(
          (filterObject: any) => filterObject?.sopKey === sopKey,
        );
        if (findedFilter) {
          sopBuilded = buildSop(sopBuilded, findedFilter.sopKey, null, true);
          handleRemoveFilter(findedFilter.newKey);
        }
      });
      return sopBuilded;
    } catch (e) {
      console.log('>>> ee', e);
    }
  };

  const handleCleanSop = () => {
    setSop(sopRaw);
    setCurrentFilters({});
    setDeals([]);
    setCurrentPage(1);
  };

  const handleChangeStep = (step: StepperData) => {
    setSteps(step);
  };

  const values = {
    sopRaw,
    sop,
    steps,
    currentFilters,
    deals,
    currentPage,
    setCurrentPage,
    setDeals,
    handleChangeSop,
    handleCleanSop,
    handleRemoveFilter,
    handleChangeStep,
  };

  return <SopContext.Provider value={values}>{children}</SopContext.Provider>;
};

const withSopContext = (Component) => (props: SopProps) =>
  (
    <SopContextProvider>
      <Component {...props} />
    </SopContextProvider>
  );

const useSop = () => React.useContext(SopContext);

export { SopContextProvider as default, SopContext, useSop, withSopContext };
