import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import apmStore from './config/apm-store';

if (process.env.REACT_APP_START_APM === 'enabled'){
    apmStore.init()
}

ReactDOM.render(<App />, document.getElementById('root'));
